<template>
  <div class="pdb-bg image-bg">
    <div class="personal-daily-briefing">
      <mobile-header></mobile-header>
      <the-header whiteColor>
        <template v-slot:right>
          <div
            data-cy="headerRightNavigation"
            class="header__right-navigation"
            v-if="isAuthenticatedUser"
          >
            <!-- <div class="weather-icon">
              <i
                class="icon navigation-icon icon-brightness weather-icon__icon"
              ></i>
              <p class="weather-icon__para">91.4°Fs</p>
            </div> -->
            <!-- <i
              class="icon navigation-icon icon-bookmark notification__icon"
            ></i> -->

            <!-- <router-link to="/workspace/write" class="btn btn-white"
              >Post Article</router-link
            > -->
            <!-- Desktop Search Bar -->
            <navbar-search :engage="false"></navbar-search>
            <!-- <a-tooltip class="link-tooltip">
              <template #title>Glocal AI</template>
              <img
                src="@/assets/icons/glocal-ai-icon-navbar-white.svg"
                alt="icon"
                @click="redirectToGlocalAI()"
                class="glocal-ai-icon"
                width="auto"
                height="auto"
              />
            </a-tooltip> -->
            <a-tooltip placement="bottom">
              <template #title>
                <h1>Requesting a new source?</h1>
                <p>
                  In Glocal you can request more publication sources if you do
                  not find them in our research source lists.
                </p>
              </template>
            </a-tooltip>
            <a-button
              class="navigation-btn"
              @click="visibleInviteToGlocalModal()"
            >
              <img
                src="@/assets/icons/forward_to_inbox.svg"
                alt="icon"
                class="contact-icon"
                width="auto"
                height="auto"
              />
              Invite Contacts
            </a-button>
            <a-tooltip class="link-tooltip">
              <template #title>Alerts</template>
              <div @click="showNotifications()" class="notification">
                <img
                  src="@/assets/icons/notifications-icon.svg"
                  alt="icon"
                  class="navigation-icon bell-icon"
                  width="auto"
                  height="auto"
                />
                <span
                  v-if="isNotifications"
                  class="notifications-alert"
                ></span>
              </div>
            </a-tooltip>
            <!-- <a-tooltip class="link-tooltip">
              <template #title>Portfolio</template>
              <img src="@/assets/icons/portfolio-white.svg" alt="icon"
                @click="openPortfolioDrawer()"
                class="navigation-icon"
              />
            </a-tooltip> -->
            <a-tooltip class="link-tooltip">
              <template #title>Network</template>
              <img
                src="@/assets/icons/diversity-icon.png"
                alt="icon"
                @click="openNetworks()"
                class="navigation-icon"
                width="auto"
                height="auto"
              />
            </a-tooltip>
            <a-tooltip class="link-tooltip profile-dropdown-tootip">
              <a-dropdown :trigger="['click']" placement="bottomRight">
                <a class="ant-dropdown-link">
                  <img
                    :src="displayPicture"
                    alt="user"
                    class="navigation-icon icon-account"
                    width="auto"
                    height="auto"
                  />
                </a>
                <template #overlay>
                  <accounts-dropdown></accounts-dropdown>
                </template>
              </a-dropdown>
            </a-tooltip>
          </div>
        </template>
      </the-header>
      <!-- <headline-ticker></headline-ticker> -->
      <pdb-section></pdb-section>
      <notification-drawer
        :drawer-visibility="showNotificationDrawer"
        @close-drawer="closeNotificationDrawer()"
        :loading="loader"
      ></notification-drawer>
      <portfolio-drawer
        :drawer-visibility="showPortfolioDrawer"
        @close-drawer="closePortfolioDrawer()"
      >
      </portfolio-drawer>
    </div>
    <!-- <button v-if="alertModalVisibilty" @click="openSubscriptionModal" class="upgrade-btn">
      <div class="upgrade-btn__logo">
        <img src="@/assets/images/logo@2x.webp" alt="logo" />
      </div>
      Upgrade to Premium
      <button @click.stop="closeAlert()" class="upgrade-btn__close-btn">
        <i class="icon icon-add_plus"></i>
      </button>
    </button> -->
    <!-- <discussions-collapse
      v-if="isDiscussionVisible"
      :isPDBPage="true"
    >
    </discussions-collapse> -->
  </div>
  <a-modal
    v-model:visible="showUserSurvayModal"
    :footer="null"
    wrapClassName="user-survey-modal"
  >
    <div class="user-survey">
      <h2 class="user-survey__title">Are you a:</h2>
      <div class="user-survey__journalist">
        <h3 class="sub-title">Journalist?</h3>
        <a-radio-group>
          <a-radio @change="forJournalist($event)" :value="true">Yes</a-radio>
          <a-radio @change="forJournalist($event)" :value="false">No</a-radio>
        </a-radio-group>
      </div>
      <div class="user-survey__sme">
        <h3 class="sub-title">Subject Matter Expert?</h3>
        <a-radio-group>
          <a-radio @change="forSME($event)" :value="true">Yes</a-radio>
          <a-radio @change="forSME($event)" :value="false">No</a-radio>
        </a-radio-group>
      </div>
      <a-button
        :loading="loading"
        class="user-survey__btn"
        @click="submitSurvey"
      >
        Submit
      </a-button>
      <div class="user-survey__dec">
        <p class="title">We ask because</p>
        <p>
          Glocal helps connect Journalist and SMEs with developing current
          events and first-hand sources. Your response can provide you this
          complimentary service as we aim to elevate the information
          environment.
        </p>
      </div>
    </div>
  </a-modal>
  <invite-to-glocal
    :visibleModal="inviteToGlocalModal"
    @closeModal="closeInviteToGlocalModal"
  >
  </invite-to-glocal>
  <welcome-to-glocal-freemium
    :visibleWelcomeFreemiumModal="welcomeToGlocal"
    @handle-close="closeWelcomeModal"
  ></welcome-to-glocal-freemium>
  <subscription-modal
    :visibleSubscriptionModal="visibleSubscriptionModal"
    @handle-close="closeSubscriptionModal"
  ></subscription-modal>
  <!-- <subscription-expired-modal
    :visibleSubscriptionExpiredModal="isSubscriptionExpired"
  ></subscription-expired-modal> -->
  <subscription-packages-modal
    :visiblePackagesModal="visiblePackagesModal"
    :remainingDay="remainingDay"
    :isSubscriptionExpired="isCloseable"
    @handle-close="closeSubscriptionPackagesModal"
  ></subscription-packages-modal>
  <send-message-modal
    :visible="visibleSendMessageModal"
    @close-modal="closeSendMessageModal"
  ></send-message-modal>
  <recovery-email-popup
    v-if="showRecoveryEmailPopup"
    @close-popup="closeSecondaryEmailPopup"
  ></recovery-email-popup>
  <!-- <topic-discussion
    v-if="isDiscussionVisible"
    :isPDBPage="true"
  ></topic-discussion> -->
</template>

<script>
import TheHeader from "../components/layout/ProfilePageHeader.vue";
import PdbSection from "../components/PdbComponents/PdbVersion1.vue";
import AccountsDropdown from "../components/BaseComponents/AccountsDropdown.vue";
import NavbarSearch from "../components/BaseComponents/NavbarSearch.vue";
import MobileHeader from "../components/layout/MobileHeader.vue";
import Cookies from "js-cookie";
import {mapActions, mapGetters} from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  title: "Discover",
  components: {
    TheHeader,
    NotificationDrawer: defineAsyncComponent(() => import('@/components/PdbComponents/Notifications.vue')),
    AccountsDropdown,
    PdbSection,
    NavbarSearch,
    InviteToGlocal: defineAsyncComponent(() => import('@/components/BaseComponents/InviteToGlocal.vue')),
    PortfolioDrawer: defineAsyncComponent(() => import('@/components/PdbComponents/PortfolioDrawer.vue')),
    WelcomeToGlocalFreemium: defineAsyncComponent(() => import('@/components/BaseComponents/Subscription/WelcomeToFreemiumModal.vue')),
    SubscriptionModal: defineAsyncComponent(() => import('@/components/BaseComponents/Subscription/SubscriptionModal.vue')),
    SubscriptionPackagesModal: defineAsyncComponent(() => import('@/components/BaseComponents/Subscription/SubscriptionPackagesModal.vue')),
    MobileHeader,
    RecoveryEmailPopup: defineAsyncComponent(() => import('@/components/BaseComponents/RecoveryEmailPopup.vue')),
    SendMessageModal: defineAsyncComponent(() => import('@/components/BaseComponents/SendMessageModal.vue')),
  },
  data() {
    return {
      showNotificationDrawer: false,
      showUserSurvayModal: false,
      isJournalist: false,
      isSME: false,
      loading: false,
      showMessageModal: false,
      message: {},
      loader: true,
      inviteToGlocalModal: false,
      visibleSubscriptionModal: false,
      signInVisible: false,
      forgotModalVisible: false,
      requestNewsSourceModal: false,
      showPortfolioDrawer: false,
      alertDays: [7, 3, 1],
      alertModalVisibilty: false,
      showRecoveryEmailPopup: false,
      doNotShow: false,
      visibleSendMessageModal: false,
      remainingDay: null,
      isCloseable: false,
    };
  },
  async mounted() {
    if (this.userProfile.is_paid === false) {
      this.$store.commit("auth/setSubscriptionPackagesVisibilty", true);
      return (this.alertModalVisibilty = true);
    } else {
      // await this.$store.dispatch("profile/getUserInformation");
      if (window.innerWidth > 576) {
        await this.$store.dispatch(
          "discover/getDiscoverModules"
        );
      }
      this.searchNewsArticles();
      this.searchInsightsArticles();
      this.$store.dispatch("profile/getUserTopicsWithOrdering");
      this.$store.commit("discover/SET_UPDATE_SOURCES", true);
      this.checkAlertStatus();
      // let articleRequestObj = {
      //   query: "",
      //   fromDate: "",
      //   toDate: "",
      //   offset: 0,
      //   limit: 9,
      //   includeSource: this.$store.getters["discover/getIncludedSources"],
      //   excludeSource: this.$store.getters["discover/getExcludedSources"],
      //   isPDB: 1,
      // };

      // let insightsRequestObj = {
      //   query: "",
      //   fromDate: "",
      //   toDate: "",
      //   offset: 0,
      //   limit: 10,
      //   includeSource: this.$store.getters["discover/getInsightsIncludedSources"],
      //   excludeSource: this.$store.getters["discover/getInsightsExcludedSources"],
      //   isPDB: 1,
      // };
      //   if(this.isAuthenticatedUser){
      //   // this.showUserSurvayModal = this.$store.getters['auth/getFirstLoginAttemp']

      //   // await this.$store.dispatch("discover/searchArticles", 0);
      //   // await this.$store.dispatch("discover/searchInsights", insightsRequestObj);
      //   if(window.innerWidth > 576){
      //     this.emitter.emit('searchingModules', true);
      //     const response = await this.$store.dispatch("discover/getDiscoverModules");
      //     if(response.status === 200){
      //       this.emitter.emit('searchingModules', false);
      //     }
      //   }
      //   this.searchNewsArticles();
      //   this.searchInsightsArticles();
      //   this.$store.dispatch("profile/fetchUserRequests");
      //   this.$store.dispatch("profile/getArticleNotificationsCount");
      //  }else {
      //   try{
      //   await this.$store.dispatch("discover/searchArticlesAnonymousUser", articleRequestObj);
      //   await this.$store.dispatch("discover/searchInsightsAnonymousUser", insightsRequestObj);
      //   } catch(err){
      //     console.log(err)
      //   }
      // }
      this.doNotShow = Cookies.get("show_recovery_email");
      if (
        this.doNotShow === "false" ||
        (this.doNotShow === undefined &&
          this.userProfile.secondary_email === null)
      ) {
        setTimeout(() => {
          Cookies.set("show_recovery_email", true);
          this.showRecoveryEmailPopup = true;
        }, 5000);
      } else if (
        this.doNotShow === "true" &&
        this.userProfile.secondary_email
      ) {
        Cookies.set("show_recovery_email", false);
        this.showRecoveryEmailPopup = false;
      }

      this.emitter.on("close-portfolio-drawer", () => {
        this.closePortfolioDrawer();
      });
      this.emitter.on("showNotificationsDrawer", () => {
        this.showNotifications();
      });
      this.emitter.on("showPorfolioDrawer", () => {
        this.openPortfolioDrawer();
      });
      this.emitter.on("showGlocalInvitationModal", () => {
        this.visibleInviteToGlocalModal();
      });
      this.emitter.on("visibleMessageModal", () => {
        this.openSendMessageModal();
      });
      this.emitter.on("closeEmailPopup", () => {
        this.closeSecondaryEmailPopup();
      });
    }
  },
  computed: {
    ...mapGetters({
      authUserInformation: 'profile/userInformation',
    }),
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    isUserRequest() {
      console.log("USER REQUEST", this.$store.getters["profile/getUserRequestCount"])
      return this.$store.getters["profile/getUserRequestCount"];
    },
    isNotifications() {
      return this.$store.getters["profile/getNotificationCount"];
    },
    isFirstLoginAttemp() {
      return this.$store.getters["auth/getFirstLoginAttemp"];
    },
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    userProfile() {
      return this.$store.getters["profile/userInformation"];
    },
    displayPicture() {
      return Object.keys(this.userProfile).includes("profile")
        ? this.userProfile.profile.profile_picture
        : "";
    },
    visiblePackagesModal() {
      // return this.alertModalVisibilty;
      return this.$store.getters['auth/getSubscriptionPackagesModalVisibility'];
    },
    startDate() {
      return Object.keys(this.userProfile).includes("active_subscription") &&
        this.userProfile.active_subscription !== null
        ? this.$store.getters["profile/userInformation"].active_subscription
            .start_date
        : "";
    },
    endDate() {
      return Object.keys(this.userProfile).includes("active_subscription") &&
        this.userProfile.active_subscription !== null
        ? this.$store.getters["profile/userInformation"].active_subscription
            .end_date
        : "";
    },
    isSubscriptionExpired() {
      return Object.keys(this.userProfile).includes("active_subscription") &&
        this.userProfile.active_subscription !== null
        ? this.userProfile.active_subscription.is_expired
        : false;
    },
    welcomeToGlocal: {
      get() {
        return this.userProfile.first_login_attempt;
      },
      set(val) {
        this.userProfile.first_login_attempt = val;
      },
    },
    showFreemiumAlertDays() {
      return Cookies.get("show_btn_once");
    },
    isDiscussionVisible() {
      const value = this.$route.params.id;
      if (value) {
        this.emitter.emit("openCollapseSection", true);
      } else {
        this.emitter.emit("openCollapseSection", false);
      }
      return value;
    },
    // isChattingPermission() {
    //   const permissions = this.$store.getters['discover/getTopicDetails'];
    //   return Object.keys(permissions).length > 0 ? permissions.interest.is_chatting_permission : false
    // }
  },
  watch: {
    visiblePackagesModal(newVal) {
      if (newVal) {
        this.visibleSubscriptionModal = false;
        // this.closeWelcomeModal();
      }
    },
    welcomeToGlocal:{
      handler(isOpen){
        this.isCloseable = isOpen
      },
      immediate: true
    }
  },
  beforeUnmount(){
    this.clearAllFilters()
  },
  methods: {
    ...mapActions({
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser',
      emptyTrendingNewsFilters: 'discover/emptyTrendingNewsFilters',
      emptyTrendingNewsSearchedKeyword: 'discover/emptyTrendingNewsSearchedKeyword',
      setPDBExactKeyWord: 'discover/setPDBExactKeyWord',
      setInsightsExactKeyWord: 'discover/setInsightsExactKeyWord',

    }),
    checkAlertStatus() {
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("-");
        return new Date(`${month}/${day}/${year}`);
      };

      const startDate = parseDate(this.startDate);
      const endDate = parseDate(this.endDate);
      const currentDate = new Date();

      const remainingDays = Math.ceil(
        (endDate - currentDate) / (1000 * 60 * 60 * 24)
      );
      if (this.isSubscriptionExpired) {
        this.remainingDay = null;
        this.alertModalVisibilty = true;
        this.$store.commit("auth/setSubscriptionPackagesVisibilty", true);
        this.isCloseable = false;
      } else if (
        !this.showFreemiumAlertDays ||
        this.showFreemiumAlertDays === "false"
      ) {
        if (this.alertDays.includes(remainingDays)) {
          this.remainingDay = remainingDays;
          this.alertModalVisibilty = true;
          this.$store.commit("auth/setSubscriptionPackagesVisibilty", true);
          this.isCloseable = true;
        }
      } else {
        if (!this.alertDays.includes(remainingDays)) {
          Cookies.set("show_btn_once", false, { expires: 30 });
        }
      }
    },
    // closeAlert() {
    //   this.alertModalVisibilty = false;
    //   Cookies.set("show_btn_once", true, { expires: 30 });
    // },
    redirectToGlocalAI() {
      this.$router.push("/glocal-AI");
    },
    async searchNewsArticles() {
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      await this.$store.dispatch("discover/searchInsights");
    },
    openPortfolioDrawer() {
      this.showPortfolioDrawer = true;
    },
    closePortfolioDrawer() {
      this.showPortfolioDrawer = false;
    },
    openNetworks(){
      this.setActiveTabKey('network')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    redirectToArchive() {
      this.setActiveTabKey('archive')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    openSendMessageModal() {
      this.visibleSendMessageModal = true;
    },
    closeSendMessageModal(msg) {
      if (msg === "success") {
        this.showMessageModal = true;
        this.message = {
          title: "Message Sent Successfully!",
          type: "success",
        };
      }
      this.visibleSendMessageModal = false;
    },
    forJournalist(val) {
      this.isJournalist = val.target.value;
    },
    forSME(val) {
      this.isSME = val.target.value;
    },
    async submitSurvey() {
      try {
        this.loading = true;
        const payload = {
          is_journalist: this.isJournalist,
          subject_matter_expert: this.isSME,
        };
        const response = await this.$store.dispatch(
          "profile/setUserSurvey",
          payload
        );
        if (response.status === 200) {
          this.showMessageModal = true;
          this.showUserSurvayModal = false;
          this.loading = false;
          this.message = {
            title: "Survey Submitted Successfully",
            type: "success",
          };
        }
      } catch (err) {
        console.log(err);
      }
    },
    showNotifications() {
      this.getFeatures();
      this.showNotificationDrawer = true;
    },
    closeNotificationDrawer() {
      this.showNotificationDrawer = false;
    },
    async getFeatures() {
      try {
        const response = await this.$store.dispatch(
          "article/getGlocalFeatures"
        );
        if (response.status === 200) {
          this.loader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    visibleInviteToGlocalModal() {
      this.inviteToGlocalModal = true;
    },
    closeInviteToGlocalModal() {
      this.inviteToGlocalModal = false;
    },
    closeWelcomeModal() {
      this.welcomeToGlocal = false;
      this.$store.dispatch("auth/firstLoginAttempt");
    },
    openSubscriptionModal() {
      this.visibleSubscriptionModal = true;
    },
    closeSubscriptionModal() {
      this.visibleSubscriptionModal = false;
    },
    closeSubscriptionPackagesModal() {
      this.$store.commit("auth/setSubscriptionPackagesVisibilty", false);
      this.alertModalVisibilty = false;
      Cookies.set("show_btn_once", true, { expires: 30 });
    },
    closeSecondaryEmailPopup() {
      this.showRecoveryEmailPopup = false;
      Cookies.set("show_recovery_email", true);
    },
    clearAllFilters(){
      this.emptyTrendingNewsFilters('trending-news')
      this.emptyTrendingNewsFilters('trending-research')
      this.$store.commit("discover/SET_INCLUDED_SOURCES", []);
      this.$store.commit("discover/SET_EXCLUDED_SOURCES", []);
      this.$store.commit("discover/PDB_INCLUDED_KEYWORDS", []);
      this.$store.commit("discover/PDB_EXCLUDED_KEYWORDS", []);
      this.setPDBExactKeyWord()
      this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", [])
      this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", []);
      this.$store.commit("discover/INSIGHTS_INCLUDED_KEYWORDS", []);
      this.$store.commit("discover/INSIGHTS_EXCLUDED_KEYWORDS", []);
      this.setInsightsExactKeyWord('')
      this.emitter.emit('clearSearchBar')
    }
  },
};
</script>

<style lang="scss">
.pdb-bg {
  background-color: $color-black;
}

.image-bg {
  //background-image: url("../assets/images/PDBbg.jpeg");
  background-color: $color-white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  //-moz-box-shadow: inset -7rem 18rem 50rem #000;
  //-webkit-box-shadow: inset -7rem 18rem 50rem #000;
  //box-shadow: inset -7rem 18rem 50rem #000;
}

.personal-daily-briefing {
  .header {
    background-color: black !important;

    &__right-navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 2;
      width: 100%;
      .navigation-icon {
        color: $color-white;
        margin-left: 3.2rem;
        width: 2.7rem;
        cursor: pointer;
        font-size: 3rem;
        @include respond(phone-large) {
          margin-left: 2rem;
        }
      }
      // .glocal-ai-icon {
      //   width: 4rem;
      //   height: auto;
      //   margin-right: auto;
      //   margin-left: 0.8rem;
      //   cursor: pointer;
      // }
      .notification {
        position: relative;
        margin-left: 3rem;
        width: 1.9rem;
        @include respond(phone-large) {
          margin-left: 2rem;
        }
        .bell-icon {
          width: 1.9rem;
          margin-left: 0;
        }
        .notifications-alert {
          position: absolute;
          content: "";
          width: 0.8rem;
          height: 0.8rem;
          background-color: red;
          border-radius: 100%;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .icon-account {
        color: $color-black !important;
        border-radius: 100%;
        border: 0.2rem solid $color-black;
        object-fit: cover;
        font-size: 3rem;
        width: 5.2rem;
        height: 5.2rem;
        background-color: #ebebeb;
      }
      .navigation-btn {
        color: $color-white;
        font-family: $font-primary-medium;
        font-size: 1.6rem;
        background-color: $color-primary;
        padding: 0 2rem;
        height: 3.8rem;
        margin-left: 2rem;
        line-height: normal;
        border: none;
        outline: none;
        text-align: center;
        @include respond(laptop-small) {
          display: none;
        }

        span {
          line-height: 1.8rem;
        }
        .plus-icon {
          width: 1.4rem;
          height: auto;
          margin-right: 1.4rem;
          margin-bottom: 0.35rem;
        }
        .contact-icon {
          width: 2rem;
          height: auto;
          margin-right: 0.9rem;
        }
      }
      .search-filter {
        display: block;
        @include respond(phone-x-small) {
          display: none;
        }
      }
    }
  }
  .profile-page-header-container {
    display: block;
    @include respond(phone-x-small) {
      display: none;
    }
  }
  .mobile-navbar-new {
    display: none;
    @include respond(phone-x-small) {
      display: block;
    }
  }
}
.upgrade-btn {
  position: fixed;
  bottom: 7rem;
  left: 2rem;
  background-color: $color-primary;
  z-index: 44;
  display: flex;
  align-items: center;
  font-family: $font-primary-medium;
  font-size: 1.6rem;
  color: $color-white;
  border: none;
  outline: none;
  padding: 0.9rem 1.8rem;
  border-radius: 100px !important;
  cursor: pointer;
  @include respond(tab-port) {
    bottom: 13rem;
  }
  &__logo {
    height: 4.3rem;
    width: 4.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black;
    border-radius: 100%;
    margin-right: 1rem;
    img {
      width: 1.8rem;
      height: auto;
    }
  }
  &__close-btn {
    margin-left: auto;
    display: block;
    height: fit-content;
    width: fit-content;
    line-height: 0;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0.2rem;
    cursor: pointer;
    margin-left: 1rem;
    .icon {
      transform: rotate(45deg);
      display: block;
      color: $color-white;
      font-size: 1.4rem;
    }
  }
}

.user-survey-modal {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .user-survey {
          padding: 2.5rem;

          &__title {
            color: $color-black;
            font-size: 2.2rem;
            font-family: $font-primary-bold;
            margin-bottom: 2rem;
          }

          .sub-title {
            color: $color-black;
            font-size: 1.8rem;
            font-family: $font-primary-bold;
          }

          .ant-radio-group {
            .ant-radio-wrapper {
              font-family: $font-primary;
              font-size: 1.6rem;
            }

            .ant-radio-wrapper-checked {
              .ant-radio {
                .ant-radio-inner {
                  border-color: $color-primary;

                  &::after {
                    background-color: $color-primary;
                  }
                }
              }
            }
          }

          &__journalist {
            margin-bottom: 2.5rem;
          }

          &__sme {
            margin-bottom: 2.5rem;
          }

          &__btn {
            font-family: $font-primary-bold;
            background-color: $color-primary;
            margin-top: 0;
            font-size: 1.6rem;
            width: 8rem;
            height: 3.5rem;
            border: none;
            color: $color-white;
            cursor: pointer;

            span {
              line-height: normal;
            }
          }

          &__dec {
            margin-top: 3rem;

            .title {
              margin-bottom: 0.5rem;
              text-decoration: underline;
            }

            p {
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 2.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
